<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="profile__link-btn"
                 v-bind="attrs"
                 v-on="on"
            >
                <span>Add an event</span>
            </div>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                Add an Event
            </v-card-title>

            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title" v-model="gig.title"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Venue" readonly v-model="venue.name"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-datetime-picker v-if="showDates" label="Starts At" v-model="gig.starts_at">
                                <template slot="timeIcon">
                                    TIME
                                </template>
                                <template slot="dateIcon">
                                    Date
                                </template>
                            </v-datetime-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-datetime-picker v-if="showDates" label="Ends At" v-model="gig.ends_at">
                                <template slot="timeIcon">
                                    TIME
                                </template>
                                <template slot="dateIcon">
                                    Date
                                </template>
                            </v-datetime-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="submitRequest">Submit a request</v-btn>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn
                                text
                                @click="dialog = false"
                            >Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>


            <!--            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                            >
                                I accept
                            </v-btn>
                        </v-card-actions>-->
        </v-card>
    </v-dialog>
</template>

<script>
import Gig from "@/models/Gig";
import Artist from "@/models/Artist";
import {mapMutations} from "vuex";

export default {
    name: "BookVenueByArtistModal",
    props: ['venue'],
    data: function () {
        return {
            dialog: false,
            showDates: true,
            gig: {
                venue_id: this.venue.id,
                title: '',
                starts_at: null,
                ends_at: null,
            }
        }
    },
    methods: {
        async submitRequest() {
            let gig = new Gig(this.gig);
            gig.is_confirmed_by_artist = true

            if (!(await gig.save()).id) {
                this.showSnackBar({
                    color: 'error',
                    timeout: 3000,
                    text: 'Something went wrong, please try again later.'
                })
                return
            }

            this.showSnackBar({color: 'success', timeout: 3000, text: 'Request sent'})
            this.dialog = false

            this.gig.title = ''
            this.gig.starts_at = null
            this.gig.ends_at = null
            this.showDates = false
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        let artist = await Artist.where('user_id', this.$auth.user().id).first();
        this.gig.artist_id = artist.id
    },
    watch: {
        dialog(val) {
            if (val) {
                this.showDates = true
            }
        }
    }
}
</script>

<style scoped>

</style>
