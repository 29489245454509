<template>
    <div>
        <div :id="id" :style="{height: height+'px'}"></div>
    </div>
</template>

<script>
    import L from "leaflet"

    export default {
        name: "open-street-map",
        props: ['lat', 'lng', 'zoom', 'id', 'height'],
        data: function () {
            return {}
        },
        mounted() {
            let zoom = this.zoom ? this.zoom : 10;
            let myMap = L.map(this.id).setView([this.lat, this.lng], zoom);
            L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                maxZoom: 18,
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1,
                accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN
            }).addTo(myMap);

            L.marker([this.lat, this.lng]).addTo(myMap)
        }
    }
</script>

<style>
    @import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");
</style>
