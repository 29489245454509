<template>
    <a v-bind:href="venue.call_to_action_url" v-on:click="recordCallToActionClick" target="_blank">
        {{ venue.call_to_action_text }}
    </a>
</template>

<script>
import VenueCallToActionClick from "@/models/VenueCallToActionClick";

export default {
    name: "CallToAction",
    props: ['venue'],
    methods: {
        recordCallToActionClick() {
            const user = this.$auth.user();

            if (user && user.id === this.venue.user_id) {
                return
            }

            const click = new VenueCallToActionClick()
            click.venue_id = this.venue.id
            click.save()
        },
    }
}
</script>

<style scoped>

</style>
