<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            max-width="600px"
            v-on:click:outside="$emit('click:outside')"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Submit a venue claim</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    type="email"
                                    :error-messages="errors.email"
                                    label="Email"
                                    v-model="email"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :error-messages="errors.phone"
                                    label="Phone"
                                    v-model="phone"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="claim">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import VenueClaimRequest from "@/models/VenueClaimRequest";

export default {
    name: "VenueClaimRequestModal",
    props: {
        venueId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            dialog: true,
            errors: [],
            email: null,
            phone: null,
        }
    },
    methods: {
        async claim() {
            const claimRequest = new VenueClaimRequest()

            claimRequest.venue_id = this.venueId
            claimRequest.email = this.email
            claimRequest.phone = this.phone

            try {
                await claimRequest.save()
            } catch (e) {
                this.errors = e.response.data.errors
                return
            }

            this.$emit('success')
        }
    }
}
</script>

<style scoped>

</style>
