<template>
    <front-layout class="venue">
        <section class="profile__uptitle page__top-line">
            <v-container py-4>
                Venue Profile
            </v-container>
        </section>
        <v-container fluid px-0>
            <v-row no-gutters>
                <v-col
                    v-if="venue.featured_image"
                    class="venue__header-img d-none d-md-block"
                    :style="`background-image: url(${venue.featured_image});`"
                >
                </v-col>
                <v-col>
                    <div class="venue__logo" v-if="avatarUrl">
                        <v-img
                            :src="avatarUrl"
                            height="100%"
                            contain
                        />
                    </div>
                    <v-container fluid>
                        <v-row class="profile__info flex-sm-row justify-center">
                            <v-col cols="auto" class="mb-12 mb-sm-0">
                                <h1>{{ venue.name }}</h1>
                                <p class="profile__link-tel" v-if="venue.phone_number">
                                    <a :href="'tel:' + venue.phone_number">
                                        {{ venue.phone_number }}
                                    </a>
                                </p>
                                <div class="d-flex justify-center align-center">
                                    <v-icon x-large class="float-left">mdi-map-marker</v-icon>
                                    <p>{{ venue.address }}</p>
                                </div>
                            </v-col>
                            <v-col
                                cols="auto"
                                v-if="displayInfoCol"
                            >
                                <book-venue-by-artist-modal
                                    v-if="displayBookEventButton && venue.id"
                                    :venue="venue"
                                />
                                <div v-if="venue.user_id === null" class="profile__link-btn">
                                    <a @click="showVenueClaimRequestModal = true">
                                        Claim
                                    </a>
                                </div>
                                <div v-if="menu" class="profile__link-btn">
                                    <router-link :to="{name: 'menus.public', params: {slug: menu.slug}}">
                                        Menu
                                    </router-link>
                                </div>
                                <div
                                    v-if="venue.call_to_action_text && venue.call_to_action_url"
                                    class="profile__link-btn"
                                >
                                    <call-to-action :venue="venue"/>
                                </div>
                                <div class="profile__link-btn" v-if="venue.website_url">
                                    <a v-bind:href="websiteUrl" @click="recordWebsiteView" target="_blank">Website</a>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <section>
            <v-container fluid>
                <v-row class="flex-column flex-lg-row white primary--text">
                    <v-col lg="6" class="py-10 px-sm-10">
                        <h3 class="text-h4 font-weight-medium mb-3">About Us</h3>
                        <p>
                            {{ venue.description }}
                        </p>
                        <div class="px-sm-12">
                            <open-street-map
                                id="venueMap"
                                v-if="ready"
                                zoom="15"
                                :lat="venue.latitude"
                                :lng="venue.longitude"
                                height="300"

                            />
                        </div>
                    </v-col>
                    <v-col lg="6" class="py-10 px-sm-10 lightGrey profile__schedule">
                        <h3 class="text-h4 font-weight-medium mb-3 text-center">Entertainment Schedule</h3>
                        <v-simple-table class="profile__gigs-table">
                            <tbody v-bind:key="`gig-${gig.id}`"
                                   v-for="gig in venue.upcoming_gigs.slice(0, 4)">
                            <tr>
                                <td colspan="4" class="font-weight-medium">
                                    {{ gig.title }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-no-wrap">
                                    {{ formatDate(gig.starts_at) }}
                                </td>
                                <td class="text-no-wrap">
                                    {{ formatTime(gig.starts_at) }} - {{ formatTime(gig.ends_at) }}
                                </td>
                                <td class="">
                                    {{ gig.artist.name }}
                                </td>
                                <td class="profile__gigs-table-btn">
                                    <router-link
                                        :to="{name: 'artist.public', params: {slug: gig.artist.slug}}">
                                        Artist profile
                                    </router-link>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                        <div class="profile__more-btn">
                            <a
                                v-if="venue.has_custom_calendar_url && venue.custom_calendar_url"
                                v-bind:href="venue.custom_calendar_url"
                                target="_blank"
                            >
                                <v-btn>See Entertainment Calendar</v-btn>
                            </a>
                            <v-btn v-else color="venuePrimary" class="white--text">See Entertainment Calendar</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section v-if="venue.gallery.length" class="white">
            <v-slide-group>
                <v-slide-item
                    v-for="(item, i) in venue.gallery"
                    :key="`slide-${i}`"
                >
                    <v-sheet
                        class="v-slide-group__slide"
                        :width="`calc((${windowWidth}px + 8px) / 3)`"
                        height="330"
                        tile
                        outlined
                    >
                        <v-img height="330" :src="item"/>
                    </v-sheet>

                </v-slide-item>
            </v-slide-group>
        </section>
        <section v-if="venue.reviews || venue.id" class="venue__reviews">
            <v-container>
                <v-row v-if="venue.reviews" class="justify-center">
                    <v-col lg="8">
                        <div v-for="review in venue.reviews" :key="`review-`+review.id">
                            <venue-review-card :review="review"/>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="showReviewForm" class="justify-center">
                    <v-col lg="8">
                        <venue-review-form :venue="venue" @review-created="init"/>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <venue-claim-request-modal
            v-if="venue && showVenueClaimRequestModal"
            v-on:click:outside="showVenueClaimRequestModal = false"
            v-on:success="venueClaimSuccess"
            :venue-id="venue.id"
        />
    </front-layout>
</template>

<script>
import FrontLayout from "../layouts/FrontLayout";
import VenueReviewForm from "@/components/cards/VenueReviewForm";
import VenueReviewCard from "@/components/cards/ArtistReviewCard";
import Venue from "@/models/Venue";
import OpenStreetMap from "@/components/OpenStreetMap";
import BookVenueByArtistModal from "@/components/modal/BookVenueByArtistModal";
import VenueProfileView from "@/models/VenueProfileView";
import VenueWebsiteView from "@/models/VenueWebsiteView";
import CallToAction from "@/components/venue/CallToAction";
import VenueClaimRequestModal from "@/components/modal/VenueClaimRequestModal";
import {mapMutations} from "vuex";

export default {
    name: "Venue",
    components: {
        VenueClaimRequestModal,
        CallToAction, BookVenueByArtistModal, OpenStreetMap, VenueReviewForm, VenueReviewCard, FrontLayout
    },
    computed: {
        displayBookEventButton() {
            const user = this.$auth.user();

            if (!user) {
                return false
            }

            if (!user.roles) {
                return false
            }

            return user.roles.includes('artist');
        },
        displayInfoCol() {
            if (this.displayBookEventButton && this.venue.id) {
                return true
            }

            if (this.menu) {
                return true
            }

            if (this.venue.call_to_action_text && this.venue.call_to_action_url) {
                return true
            }

            if (this.venue.user_id === null) {
                return true
            }

            return !!this.venue.website_url;
        },
        websiteUrl() {
            return this.venue.website_url
        },
        windowWidth() {
            return document.documentElement.clientWidth
        },
        avatarUrl() {
            return this.venue.user ? this.venue.user.avatar_url : null;
        },
        showReviewForm() {
            if (!this.venue.id) {
                return false;
            }

            const user = this.$auth.user();

            if (!user) {
                return false;
            }

            return !user["roles"].includes('admin')
        },
    },
    data: function () {
        return {
            venue: {
                user: {},
                upcoming_gigs: [],
                gallery: [],
                reviews: null,
            },
            menu: null,
            marker: null,
            ready: false,
            showVenueClaimRequestModal: false
        }
    },
    methods: {
        async init() {
            const venueSlug = this.$route.params.slug;

            if (!venueSlug) {
                return;
            }

            this.venue = await Venue.custom('venues/slug/' + venueSlug).first()
            this.menu = (this.venue.menus && this.venue.menus.length) ? this.venue.menus[0] : null
            this.ready = true

            const user = this.$auth.user();
            if (!user || user.id !== this.venue.user_id) {
                const profileView = new VenueProfileView({venue_id: this.venue.id});
                await profileView.save()
            }
        },
        formatDate(date) {
            return this.$date(date).format('dddd, MMM DD, YYYY')
        },
        formatTime(date) {
            return this.$date(date).format('HH:mm')
        },
        recordWebsiteView() {
            const user = this.$auth.user();

            if (user && user.id === this.venue.user_id) {
                return
            }

            const websiteView = new VenueWebsiteView()
            websiteView.venue_id = this.venue.id
            websiteView.save()
        },
        venueClaimSuccess() {
            this.showVenueClaimRequestModal = false
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Claim submitted'})
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        await this.init()
    }
}
</script>

<style>
.leaflet-pane {
    z-index: 100 !important;
}
</style>
