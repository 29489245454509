<template>
    <v-card>
        <v-card-title>Review for {{ venue.name }}</v-card-title>
        <v-card-text class="pb-0">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-row>
                    <v-col class="d-flex" cols="12">
                        <div>
                                <span class="text-uppercase">
                       Overall
                  </span>
                            <v-rating
                                class="ml-4"
                                v-model="review.score"
                                color="yellow accent-4"
                                dense
                                empty-icon="mdi-star"
                                background-color="#9a9a9a"
                                half-icon="mdi-star-half-full"
                                half-increments
                                size="18"
                            ></v-rating>
                        </div>

                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            class="ml-4"
                            outlined
                            name="input-7-4"
                            label="Write something about the venue"
                            v-model="review.text"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 justify-end p-4">
            <v-btn :loading="loading" @click="submitReview" color="primary">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Review from "@/models/Review";

export default {
    name: "VenueReviewForm",
    props: ['venue'],
    data: function() {
        return {
            review: {
                score: 0,
                text: null,
                reviewer_type: null,
                reviewer_user_id: null,
                reviewable_type: 'App\\Models\\Venue',
                reviewable_id: this.venue.id,
            },
            disabled: false,
            loading: false,
        }
    },
    methods: {
        async submitReview() {
            let review = new Review(this.review)
            this.review = await review.save()
            this.$emit('review-created')
            this.review.text = null
            this.disabled = true
        },
        getReviewerType() {
            const user = this.$auth.user()
            if (! user || ! user.roles || user.roles.length === 0) {
                return false
            }
            if (user.roles.includes('fan')) {
                return  'App\\Models\\Fan'
            }
            if (user.roles.includes('artist')) {
                return  'App\\Models\\Artist'
            }
            if (user.roles.includes('venue')) {
                return  'App\\Models\\Venue'
            }
        }
    },
    mounted() {
        this.review.reviewer_type = this.getReviewerType()
        this.review.reviewer_user_id = this.$auth.user() ? this.$auth.user().id :null

        if (! this.review.reviewer_type || ! this.review.reviewer_user_id) {
            this.disabled = true
        }
    }
}
</script>

<style scoped>

</style>
